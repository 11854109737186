<template>
  <div class="lg:text-xl for-men home relative text-base">
    <a href="#" class="fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z" fill="rgba(255,255,255,1)" />
      </svg>
    </a>
    <SiteHeaderBH />
    <div class="spacer lg:pt-0 pt-8"></div>
    <section class="lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center">
      <div class="md:w-1/3 lg:w-1/2 bg-men absolute top-0 right-0 w-1/2 h-full bg-left bg-cover"></div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto">
        <h2 class="lg:font-thin lg:text-6xl text-4xl font-light">Untuk Pria</h2>
        <p class="lg:text-base text-sm">Beranda > <span class="text-sitePurple font-bold">Untuk Pria</span></p>
      </div>
    </section>
    <div class="flex w-full">
      <div class="bg-siteOPurple w-1/3 py-2"></div>
      <div class="bg-sitePPurple w-1/3 py-2"></div>
      <div class="bg-sitePurple w-1/3 py-2"></div>
    </div>
    <section class="text-gray-800">
      <div class="md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto">
        <div class="lg:items-center flex flex-wrap -mx-4 overflow-hidden">
          <div class="w-full px-4 overflow-hidden">
            <p class="mb-8">
              Menurut pengamatan Dr Marco, kelompok pasien yang semakin berkembang adalah pria. Dari mereka yang mencari
              perawatan kecil hingga mereka yang mencari peremajaan total, banyak pria beralih ke dokter berpengalaman
              seperti Dr Marco untuk perubahan yang aman dan efektif.
            </p>
            <a href="tel:+6564648075"
              class="lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left">Untuk mengetahui lebih
              lanjut tentang perawatan pribadi Dr Marco untuk pria, hubungi kami di +65 6464 8075 sekarang.</a>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-01.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Ginekomastia</h4>
                <p class="mb-4">
                  Ginekomastia, juga dikenal sebagai payudara pria yang membesar, terjadi ketika kelebihan timbunan
                  lemak lokal menumpuk di dalam dada. Menggunakan sayatan kecil, Dr Marco menghilangkan lemak melalui
                  sedot lemak sambil mengencangkan dan menghilangkan kelebihan kulit untuk dada yang berkontur dan lebih
                  kencang.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Beberapa tanda umum ginekomastia adalah:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Timbunan lemak lokal di dada</li>
                  <li class="mb-1">Melimpahnya jaringan kelenjar di payudara</li>
                  <li>Kelebihan kulit payudara</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Alasan memilih Dr Marco antara lain:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Operasi cepat 1-2 jam</li>
                  <li class="mb-1">Prosedur rawat jalan yang memungkinkan istirahat di rumah</li>
                  <li class="mb-1">Ahli bedah berpengalaman untuk hasil yang lebih aman</li>
                  <li>Didedikasikan untuk memahat dan mendesain bentuk payudara pria maskulin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-02.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Abdominoplasti</h4>
                <p class="mb-4">
                  Berlawanan dengan kepercayaan populer, banyak pria juga menjalani abdominoplasti karena otot perut
                  yang meregang. Abdominoplasti/tummy tuck mungkin memenuhi syarat untuk klaim asuransi jika perbaikan
                  otot diperlukan.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Ini cocok untuk pria yang:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Memiliki kelebihan lemak yang membandel di sekitar bagian tengah tubuh</li>
                  <li class="mb-1">Tidak kelebihan berat badan</li>
                  <li class="mb-1">Ingin mendapatkan kembali perut yang rata</li>
                  <li>Yang menginginkan otot tulang belakang dan inti yang lebih kuat dan stabil</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Alasan memilih Dr Marco antara lain:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Tersedia berbagai metode abdominoplasti</li>
                  <li class="mb-1">Ahli bedah berpengalaman untuk hasil yang lebih aman</li>
                  <li>Prosedur menyeluruh dan aman untuk menghilangkan lemak dan kulit berlebih</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800 bg-gray-200">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-03.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Sedot Lemak</h4>
                <p class="mb-4">
                  Pria sering menghadapi timbunan lemak lokal yang membandel seiring bertambahnya usia. Untungnya, ini
                  bisa dihilangkan dan liposculpting bisa dilakukan untuk mendapatkan bentuk tubuh yang lebih ramping
                  dan langsing.
                </p>
                <p class="lg:text-xl mb-4 text-lg font-bold">Ini cocok untuk pria yang:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Rentan terhadap kantong lemak yang tidak merespons olahraga atau diet</li>
                  <li class="mb-1">Memiliki lebih banyak timbunan lemak yang ditemukan di paha, perut dan bagian tengah
                    tubuh</li>
                  <li>Keinginan untuk meningkatkan kepercayaan diri dan pakaian yang lebih pas</li>
                </ul>
                <p class="lg:text-xl mb-4 text-lg font-bold">Alasan memilih Dr Marco antara lain:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">“Penanganan halus” yang efektif dari area yang bermasalah</li>
                  <li class="mb-1">Meningkatkan kepercayaan diri dengan pengalaman internasional Dr Marco</li>
                  <li>Perawatan pasca-prosedur lengkap</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="text-gray-800">
      <div class="lg:px-24">
        <div class="lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto">
          <div class="lg:-mx-4 flex flex-wrap overflow-hidden">
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <img class="lg:ml-auto mb-4" src="images/image-men-05.jpg">
            </div>
            <div class="md:w-1/2 w-full px-4 overflow-hidden">
              <div class="lg:pl-8">
                <h4 class="lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase">Bedah Wajah Lainnya
                </h4>
                <p class="lg:text-xl mb-4 text-lg font-bold">Beberapa perawatan wajah lain yang juga dapat dimanfaatkan
                  pria antara lain:</p>
                <ul class="pl-5 mb-4 list-disc">
                  <li class="mb-1">Suntik pengisian kulit</li>
                  <li class="mb-1">Cangkok lemak</li>
                  <li class="mb-1">Penghapusan kerutan dan garis ekspresi</li>
                  <li class="mb-1">Pengencangan wajah</li>
                  <li class="mb-1">Pengangkatan leher</li>
                  <li class="mb-1">Pengangkatan alis</li>
                  <li class="mb-1">Operasi Kelopak Mata Atas dan Bawah</li>
                  <li>Rhinoplasty</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <SiteDoctorBH />
    <SiteConsultationBH />
    <SiteFormBH />
    <SiteFooterBH />
  </div>
</template>

<script>
  import SiteHeaderBH from '@/components/SiteHeaderBH.vue'
  import SiteDoctorBH from '@/components/SiteDoctorBH.vue'
  import SiteConsultationBH from '@/components/SiteConsultationBH.vue'
  import SiteFormBH from '@/components/SiteFormBH.vue'
  import SiteFooterBH from '@/components/SiteFooterBH.vue'
  export default {
    components: {
      SiteHeaderBH,
      SiteDoctorBH,
      SiteConsultationBH,
      SiteFormBH,
      SiteFooterBH
    },
    mounted() {  
      document.title = "Dr Marco Faria Correa Plastic Surgery - Operasi Plastik";  
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>

<style>
  .bg-sub-banner {
    background-image: url(/images/bg-sub-banner.jpg);
  }
  .bg-men {
    background-image: url(/images/bg-men.png);
  }
</style>