var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:text-xl for-men home relative text-base"},[_c('a',{staticClass:"fixed bottom-0 right-0 z-50 mb-4 mr-4 bg-gray-800 border border-white rounded-full",attrs:{"href":"#"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"48","height":"48"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z","fill":"rgba(255,255,255,1)"}})])]),_c('SiteHeaderBH'),_c('div',{staticClass:"spacer lg:pt-0 pt-8"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('SiteDoctorBH'),_c('SiteConsultationBH'),_c('SiteFormBH'),_c('SiteFooterBH')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"lg:pt-24 lg:bg-cover bg-sub-banner relative pt-12 overflow-hidden text-gray-800 bg-center"},[_c('div',{staticClass:"md:w-1/3 lg:w-1/2 bg-men absolute top-0 right-0 w-1/2 h-full bg-left bg-cover"}),_c('div',{staticClass:"max-w-screen-xl px-4 py-16 mx-auto"},[_c('h2',{staticClass:"lg:font-thin lg:text-6xl text-4xl font-light"},[_vm._v("Untuk Pria")]),_c('p',{staticClass:"lg:text-base text-sm"},[_vm._v("Beranda > "),_c('span',{staticClass:"text-sitePurple font-bold"},[_vm._v("Untuk Pria")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-full"},[_c('div',{staticClass:"bg-siteOPurple w-1/3 py-2"}),_c('div',{staticClass:"bg-sitePPurple w-1/3 py-2"}),_c('div',{staticClass:"bg-sitePurple w-1/3 py-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 max-w-screen-xl px-4 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:items-center flex flex-wrap -mx-4 overflow-hidden"},[_c('div',{staticClass:"w-full px-4 overflow-hidden"},[_c('p',{staticClass:"mb-8"},[_vm._v(" Menurut pengamatan Dr Marco, kelompok pasien yang semakin berkembang adalah pria. Dari mereka yang mencari perawatan kecil hingga mereka yang mencari peremajaan total, banyak pria beralih ke dokter berpengalaman seperti Dr Marco untuk perubahan yang aman dan efektif. ")]),_c('a',{staticClass:"lg:text-xl text-sitePurple inline-block w-full mb-4 text-lg text-left",attrs:{"href":"tel:+6564648075"}},[_vm._v("Untuk mengetahui lebih lanjut tentang perawatan pribadi Dr Marco untuk pria, hubungi kami di +65 6464 8075 sekarang.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800 bg-gray-200"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-01.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Ginekomastia")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Ginekomastia, juga dikenal sebagai payudara pria yang membesar, terjadi ketika kelebihan timbunan lemak lokal menumpuk di dalam dada. Menggunakan sayatan kecil, Dr Marco menghilangkan lemak melalui sedot lemak sambil mengencangkan dan menghilangkan kelebihan kulit untuk dada yang berkontur dan lebih kencang. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Beberapa tanda umum ginekomastia adalah:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Timbunan lemak lokal di dada")]),_c('li',{staticClass:"mb-1"},[_vm._v("Melimpahnya jaringan kelenjar di payudara")]),_c('li',[_vm._v("Kelebihan kulit payudara")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Alasan memilih Dr Marco antara lain:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Operasi cepat 1-2 jam")]),_c('li',{staticClass:"mb-1"},[_vm._v("Prosedur rawat jalan yang memungkinkan istirahat di rumah")]),_c('li',{staticClass:"mb-1"},[_vm._v("Ahli bedah berpengalaman untuk hasil yang lebih aman")]),_c('li',[_vm._v("Didedikasikan untuk memahat dan mendesain bentuk payudara pria maskulin")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-02.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Abdominoplasti")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Berlawanan dengan kepercayaan populer, banyak pria juga menjalani abdominoplasti karena otot perut yang meregang. Abdominoplasti/tummy tuck mungkin memenuhi syarat untuk klaim asuransi jika perbaikan otot diperlukan. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Ini cocok untuk pria yang:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Memiliki kelebihan lemak yang membandel di sekitar bagian tengah tubuh")]),_c('li',{staticClass:"mb-1"},[_vm._v("Tidak kelebihan berat badan")]),_c('li',{staticClass:"mb-1"},[_vm._v("Ingin mendapatkan kembali perut yang rata")]),_c('li',[_vm._v("Yang menginginkan otot tulang belakang dan inti yang lebih kuat dan stabil")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Alasan memilih Dr Marco antara lain:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Tersedia berbagai metode abdominoplasti")]),_c('li',{staticClass:"mb-1"},[_vm._v("Ahli bedah berpengalaman untuk hasil yang lebih aman")]),_c('li',[_vm._v("Prosedur menyeluruh dan aman untuk menghilangkan lemak dan kulit berlebih")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800 bg-gray-200"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-03.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Sedot Lemak")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Pria sering menghadapi timbunan lemak lokal yang membandel seiring bertambahnya usia. Untungnya, ini bisa dihilangkan dan liposculpting bisa dilakukan untuk mendapatkan bentuk tubuh yang lebih ramping dan langsing. ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Ini cocok untuk pria yang:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Rentan terhadap kantong lemak yang tidak merespons olahraga atau diet")]),_c('li',{staticClass:"mb-1"},[_vm._v("Memiliki lebih banyak timbunan lemak yang ditemukan di paha, perut dan bagian tengah tubuh")]),_c('li',[_vm._v("Keinginan untuk meningkatkan kepercayaan diri dan pakaian yang lebih pas")])]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Alasan memilih Dr Marco antara lain:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("“Penanganan halus” yang efektif dari area yang bermasalah")]),_c('li',{staticClass:"mb-1"},[_vm._v("Meningkatkan kepercayaan diri dengan pengalaman internasional Dr Marco")]),_c('li',[_vm._v("Perawatan pasca-prosedur lengkap")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-gray-800"},[_c('div',{staticClass:"lg:px-24"},[_c('div',{staticClass:"lg:px-24 md:pt-16 md:pb-12 lg:pt-20 lg:pb-16 pt-12 pb-8 mx-auto"},[_c('div',{staticClass:"lg:-mx-4 flex flex-wrap overflow-hidden"},[_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('img',{staticClass:"lg:ml-auto mb-4",attrs:{"src":"images/image-men-05.jpg"}})]),_c('div',{staticClass:"md:w-1/2 w-full px-4 overflow-hidden"},[_c('div',{staticClass:"lg:pl-8"},[_c('h4',{staticClass:"lg:mb-6 lg:text-2xl text-sitePurple mb-4 text-xl font-bold uppercase"},[_vm._v("Bedah Wajah Lainnya ")]),_c('p',{staticClass:"lg:text-xl mb-4 text-lg font-bold"},[_vm._v("Beberapa perawatan wajah lain yang juga dapat dimanfaatkan pria antara lain:")]),_c('ul',{staticClass:"pl-5 mb-4 list-disc"},[_c('li',{staticClass:"mb-1"},[_vm._v("Suntik pengisian kulit")]),_c('li',{staticClass:"mb-1"},[_vm._v("Cangkok lemak")]),_c('li',{staticClass:"mb-1"},[_vm._v("Penghapusan kerutan dan garis ekspresi")]),_c('li',{staticClass:"mb-1"},[_vm._v("Pengencangan wajah")]),_c('li',{staticClass:"mb-1"},[_vm._v("Pengangkatan leher")]),_c('li',{staticClass:"mb-1"},[_vm._v("Pengangkatan alis")]),_c('li',{staticClass:"mb-1"},[_vm._v("Operasi Kelopak Mata Atas dan Bawah")]),_c('li',[_vm._v("Rhinoplasty")])])])])])])])])
}]

export { render, staticRenderFns }